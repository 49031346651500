<template>
  <section>
    <div
      v-if="loading"
      class="flex flex-col justify-center items-center mt-100 py-48"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <div
        v-if="page"
        class="flex flex-col w-full mt-4 heti"
      >
        <div
          v-if="page.type == 'HTML'"
          :inner-html.prop="page.content | htmlXss"
          class="my-4 w-full text-indent-2em px-4"
        />

        <div
          v-if="page.type == 'Markdown'"
          :inner-html.prop="page.markdown | markdown"
          class="w-full mt-2 heti px-4 my-4 pb-8"
        />
      </div>
      <van-empty
        v-else
        description="无法加载内容信息"
      />
    </template>
  </section>
</template>

<script>
import share from '@/mixins/share'
import common from '@/mixins/common'
import { mapState } from 'vuex'
export default {
  name: 'PublicPageViewer',
  mixins: [share, common],
  data() {
    return {
      loading: true,
      page: null
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['db', '_'])
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    if (this.page) {
      let options = {
        title: this.page.pageName,
        desc: this.page.summary ?? ''
      }
      options.imgUrl = this.page.shareImage
      this.updateShareData(options)
    }
    this.setTitle(this.page.pageName)
    const heti = new Heti('.heti')
    heti.autoSpacing()
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        let result = await this.db
          .collection('pages')
          .where({
            pageId: this._.eq(this.$route.params.page)
          })
          .get()
        // eslint-disable-next-line no-console
        console.warn('获取到相关内容', result)
        this.$set(this, 'page', result.data?.[0] ?? null)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.result = null
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
